<template>
  <section class="bg-blue4">
    <h2 class="fw-bold text-blue2">
      {{ $lang('section4.title') }}
    </h2>

    <Swiper :modules="modules" :pagination="true" loop :breakpoints="breakpoints" class="pb-5">
      <SwiperSlide v-for="(item, i) of $lang('section4.items')" :key="i">
        <div class="card card-image">
          <div class="card-header">

            <div class="img-container" :style="`background-image: url('${item.img}')`" />
          </div>
          <div class="card-body">
            <h5 v-html="item.text" />
            <p v-if="item.description">{{ item.description }}</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <button class="btn btn-lg btn-blue2 mt-5">
      {{ $lang('section4.button') }}
    </button>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      0: {
        slidesPerView: 1.25,
        spaceBetween: 10,
        centeredSlides: true,
      },
      512: {
        slidesPerView: 2.8,
        spaceBetween: 10,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 20,
        centeredSlides: true,
      },
    },
  }),
};
</script>

<style></style>
