<template>
  <div class="landing0823">
    <!-- section 1 -->
    <section class="text-center text-lg-left">
      <div class="img-container">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 mb-5 mb-lg-0">
              <img src="/landings/landing_0823/svgs/Group_503.svg" class="d-none d-md-inline-block mb-4" />
              <img src="/landings/landing_0823/svgs/ned_logo.svg" class="d-inline-block d-md-none mb-4" />
              <br />
              <h1 v-html="$lang('header.title')" />
              <h3 class="d-none d-lg-block text-white">{{ $lang('header.subtitle') }}</h3>
            </div>
            <div class="col-lg-6 offset-lg-1 d-none d-lg-block">
              <Form />
            </div>
          </div>
        </div>
      </div>

      <div class="container d-block d-lg-none">
        <Form />
      </div>
    </section>

    <!-- section 2 -->
    <section class="bg-green">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-1 order-lg-0">
            <CarouselImage :images="carouselImages" class="carousel" />
          </div>
          <div class="col-lg-5 mb-5 mb-lg-0 offset-lg-1">
            <h2 class="fw-bold text-blue mb-4">
              {{ $lang('section1.title') }}
            </h2>

            <ul class="text-blue1">
              <li>
                <img src="/landings/landing_0823/imgs/CheckCircleOutline.png" />
                <span v-html="$lang('section1.items')[0]" />
              </li>
              <li>
                <img src="/landings/landing_0823/imgs/chart-column-grow-svgrepo-com 1.png" />
                <span v-html="$lang('section1.items')[1]" />
              </li>
              <li>
                <img src="/landings/landing_0823/imgs/id-card-svgrepo-com (1) 2.png" />
                <span v-html="$lang('section1.items')[2]" />
              </li>
              <li>
                <img src="/landings/landing_0823/imgs/airplane-plane-flight-svgrepo-com 1.png" />
                <span v-html="$lang('section1.items')[3]" />
              </li>
            </ul>
          </div>

        </div>
      </div>
    </section>

    <!-- section 3 -->
    <section class="bg-blue1">
      <div class="container">
        <div class="row align-items-strech">
          <div class="col-lg-6 my-lg-7">
            <h2 v-html="$lang('section2.title')" />

            <div class="d-none d-lg-block">
              <h3 v-html="$lang('section2.subtitle')" />

              <img src="/landings/landing_0823/imgs/Flags.png" />
            </div>
          </div>
          <div class="col-lg-6">
            <img src="/landings/landing_0823/imgs/Photos.png" class="photos d-none d-lg-block" />
            <img src="/landings/landing_0823/imgs/photos-mobile.png" class="photos d-lg-none" />
          </div>
          <div class="col-12 d-lg-none">
            <h3 v-html="$lang('section2.subtitle')" />
            <img src="/landings/landing_0823/imgs/flags-mobile.png" />

            <button class="btn btn-blue2 btn-lg mt-5" @click="toForm">
              {{ $lang('section2.button') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- section 4 -->
    <section>
      <div class="row">
        <div class="col-md-6 bg-green2">
          <div class="container half-container-md half-container-left py-8">
            <h2 class="mb-5">{{ $lang('section3.dublin.title') }}</h2>
            <CarouselImage :images="dublinImages" class="mb-5" />
            <p class="mb-5">{{ $lang('section3.dublin.subtitle') }}</p>
            <button class="btn btn-lg btn-blue2" @click="toForm">
              {{ $lang('section3.dublin.button') }}
            </button>
          </div>
        </div>
        <div class="col-md-6 bg-blue3 py-8">
          <div class="container half-container-md half-container-right text-md-right">
            <h2 class="mb-5">
              {{ $lang('section3.limerick.title') }}
            </h2>
            <CarouselImage :images="limerickImages" class="mb-5" />

            <p class="mb-5">{{ $lang('section3.limerick.subtitle') }}</p>
            <button class="btn btn-lg btn-blue2" @click="toForm">
              {{ $lang('section3.limerick.button') }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- section 5 -->
    <ServicesSection />

    <!-- testimonialsection -->
    <TestimonialSection class="py-8 bg-blue2 text-center">
      <template #title>
        <h2 class="text-white mb-4">{{ $lang('section5.title') }}</h2>
      </template>

      <template #cta>
        <button class="btn btn-lg btn-green mt-5" @click="toForm">
          {{ $lang('section5.button') }}
        </button>
      </template>
    </TestimonialSection>

    <!-- certificationsection -->
    <CertificationsSection class="py-8 bg-grey text-center">
      <template #cta>
        <button class="btn btn-lg btn-blue2 mt-5" @click="toForm">
          {{ $lang('section6.button') }}
        </button>
      </template>
    </CertificationsSection>
  </div>
  <LandingFooter />
</template>
<script>
import TestimonialSection from "@/components/TestimonialSection";
import CertificationsSection from "@/components/CertificationsSection";
import LandingFooter from "@/components/LandingFooter.vue";
import CarouselImage from "@/components/CarouselImage.vue";
import ServicesSection from "./ServicesSection.vue";
import Form from './Form';

export default {
  components: {
    TestimonialSection,
    CertificationsSection,
    LandingFooter,
    CarouselImage,
    ServicesSection,
    Form
  },
  data: () => ({
    carouselImages: [
      '/landings/landing_0823/imgs/carousel_2.png',
      '/landings/landing_0823/imgs/carousel_3.png',
      '/landings/landing_0823/imgs/carousel_4.png',
      '/landings/landing_0823/imgs/carousel_5.png',
      '/landings/landing_0823/imgs/carousel_6.png',
    ],
    dublinImages: [
      '/landings/landing_0823/imgs/dublin_01.jpeg',
      '/landings/landing_0823/imgs/dublin_02.jpeg',
      '/landings/landing_0823/imgs/dublin_03.jpeg',
      '/landings/landing_0823/imgs/dublin_04.jpeg',
      '/landings/landing_0823/imgs/dublin_05.jpeg',
      '/landings/landing_0823/imgs/dublin_06.jpeg',
      '/landings/landing_0823/imgs/dublin_07.jpeg',
      '/landings/landing_0823/imgs/dublin_08.jpeg',
      '/landings/landing_0823/imgs/dublin_09.jpeg',
    ],
    limerickImages: [
      '/landings/landing_0823/imgs/limerick_01.jpeg',
      '/landings/landing_0823/imgs/limerick_02.jpeg',
      '/landings/landing_0823/imgs/limerick_03.jpeg',
      '/landings/landing_0823/imgs/limerick_04.jpeg',
      '/landings/landing_0823/imgs/limerick_05.jpeg',
      '/landings/landing_0823/imgs/limerick_06.jpeg',
      '/landings/landing_0823/imgs/limerick_07.jpeg',
      '/landings/landing_0823/imgs/limerick_08.jpeg',
      '/landings/landing_0823/imgs/limerick_09.jpeg',
    ]
  }),
  methods: {
    toForm() {
      window.scroll(0, 0)
    }
  }
}
</script>