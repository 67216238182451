<template>
  <Swiper :modules="modules" :pagination="true" loop :space-between="20">
    <SwiperSlide v-for="image of images" :key="image">
      <div class="img-container" :style="`background-image: url('${image}')`"></div>
    </SwiperSlide>
  </Swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  props: {
    images: Array
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination],
  }),
};
</script>
