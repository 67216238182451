<template>
  <div class="header_form bg-grey" id="form">
    <h4 class="text-blue2 d-lg-none">{{ $lang('form.title') }}</h4>

    <form class="form-row" @submit.prevent="onSubmit">
      <div class="col-12">
        <input type="text" :placeholder="$lang('form.name')" v-model="form.name" />
      </div>
      <div class="col-6">
        <input type="text" :placeholder="$lang('form.email')" v-model="form.email" />
      </div>
      <div class="col-6">
        <input type="text" :placeholder="$lang('form.whatsapp')" v-model="form.whatsapp" />
      </div>
      <div class="col-12">
        <FormSelect
        v-model="form.nationality"
        :label="$lang('form.nationality')"
        :options="nationalities"
      />
      </div>
      <div class="col-12">
        <FormSelect
        v-model="form.campus"
        :label="$lang('form.campus')"
        :options="campuses"
      />
      </div>
      <div class="col-12">
        <button class="btn btn-blue-grad2 btn-block">{{ $lang('form.button') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import countries from "@/assets/json/countries-en.json";
import FormSelect from "@/components/Form/FormSelect";
import axios from "axios";

export default {
  components: {
    FormSelect,
  },
  data: () => ({
    countries,
    campuses: [
      { value: "dublin", label: "Dublin" },
      { value: "limerick", label: "Limerick" },
    ],
    form: {
      name: null,
      email: null,
      whatsapp: null,
      nationality: null,
      campus: null,
    },
  }),
  computed: {
    nationalities() {
      return countries.map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: null,
        email: null,
        whatsapp: null,
        nationality: null,
        campus: null,
      };
    },
    onSubmit() {
      // create a switch to have the PML token based on the campus (this.$route.params.campus) and language (this.$route.params.lang) in the route
      var token = '';
      if(this.$route.params.campus === 'dublin'){
        switch (this.$route.params.lang) {
          case 'en':
            token = 'f9dp5g11621hnrnv15an917389v9kqn';
            break;
          case 'es':
            token = 'ioievb1162154vji152u317389ihiq0';
            break;
          case 'pr':
            token = '6dl8jt1162m3fj00152q317389oc336';
            break;
          case 'cn':
            token = 'pqcrt71162o9gkqg1502p17389hz9lp';
            break;
          case 'mn':
            token = 'ujxnjl11623cgokt15rw517389x3jjt';
            break;
          default:
            break;
        }
      }
      else if(this.$route.params.campus === 'limerick'){
        switch (this.$route.params.lang) {
          case 'en':
            token = 'xx74pp11628agb1e15ung17389xex1v';
            break;
          case 'es':
            token = 'sb6flu1162r6yac7153qq17389sexng';
            break;
          case 'pr':
            token = 'c82fvc1162pr3nzw1514217389f283n';
            break;
          case 'cn':
            token = 'dsh6oa1162iyuitj158un17389xc8zx';
            break;
          case 'mn':
            token = '3baji7116246huwb153pi17389tyc68';
            break;
          default:
            break;
        }
      }

      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-name": this.form.name,
            "your-email": this.form.email,
            "your-phone": this.form.whatsapp,
            "your-nationality": this.form.nationality,
            "your-campus": this.form.campus,
            token: token,
          },
        })
        .then(() => {
          this.$router.push({ name: 'studywithus_thanks' })
        });
    },
  },
};
</script>
